@import url("./scheme-colors.css");

/* ==========================================================================
  01. GENERAL & BASIC STYLES
  =========================================================================== */

html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

/* body {
  font-family: "Jost", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #6f6f6f;
  line-height: 1.6666;
  font-weight: 400;
  background-color: #f8f5f2;
} */

body {
  /* font-family: "Bebas Neue", "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-family: "Roboto", sans-serif;
  color: #6f6f6f;
  line-height: 1.6666;
  font-weight: 400;
  background-color: #f8f5f2;
  letter-spacing: 1px;
}

/*------------- ------------- -------------
            scroll bar color
------------- ------------- -------------*/

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
}

::-webkit-scrollbar-track {
  background-color: var(--primary-color);
}

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

.rel,
section,
.container {
  position: relative !important;
  z-index: 3;
}

.section-overlay {
  width: 100%;
  height: 100%;
}

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/
.mt-6 {
  margin-top: 4rem !important;
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.py-8 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.pt-8 {
  padding-top: 6rem !important;
}

/*------------------------------------------*/
/*  Custom code
/*------------------------------------------*/

.slider-area2 {
  background-image: url(../../../public/images/pageHeader.webp);
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-area2 .hero-cap h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 800;
  text-transform: capitalize;
  line-height: 1;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-area2 .hero-cap h2 {
    font-size: 50px;
  }
}

@media (max-width: 575px) {
  .slider-area2 .hero-cap h2 {
    font-size: 35px;
  }
}

.slider-area2 .hero-cap h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 800;
  text-transform: capitalize;
  line-height: 1;
}

.slider-height2 {
  height: 450px;
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height2 {
    height: 350px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height2 {
    height: 260px;
  }
}

@media (max-width: 575px) {
  .slider-height2 {
    height: 360px;
  }
}

/* Waitlist height */
.mobile-height {
  height: 600px;
}

@media (max-width: 678px) {
  .mobile-height {
    height: 380px;
  }

  .waitlist-height {
    height: 550px;
  }
}

.waitlist-box {
  background-color: rgba(255, 255, 255, 0.5);
}

.form-control {
  border: 1px solid var(--primary-color) !important;
}

.form-control:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.pt-default {
  padding-top: 100px;
}

.py-default {
  padding: 100px 0;
}

.extra-large-modal {
  max-width: 62vw !important;
}
@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .extra-large-modal {
    max-width: 70vw !important;
  }
}
@media (max-width: 767px) {
  .extra-large-modal {
    max-width: 90vw !important;
  }
}

/*------------------------------------------*/
/*  Waitlist page
/*------------------------------------------*/

.d-sch-table {
  position: relative;
  margin-bottom: 15px;
}

.d-sch-table .d-content {
  font-family: var(--title-font);
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.2em;
  margin-top: 10px;
}

.d-sch-table .d-deco {
  position: absolute;
  width: 100%;
  height: 100%;
  border: solid 1px var(--primary-color);
  top: 10px;
  left: 10px;
}

.d-sch-table .d-col {
  border-bottom: solid 1px rgba(var(--primary-color-rgb), 0.5);
  padding: 20px 0;
  margin-bottom: 2px;
}

.d-sch-table .d-col:last-child {
  border-bottom: none;
}

.d-sch-table .d-content {
  font-family: var(--title-font);
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.2em;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .d-sch-table .d-content {
    font-size: 22px;
  }
}

/*------------------------------------------*/
/*  Home Banner
/*------------------------------------------*/

.theme-btn {
  display: inline-block;

  position: relative;

  text-decoration: none;

  z-index: 1;

  padding: 0;

  border: 0;

  transition: 0.3s;

  cursor: pointer;
}

.btn-text {
  padding: 16px 35px;

  background-color: var(--primary-color);

  text-transform: uppercase;

  color: #000000;

  display: inline-block;

  line-height: 1;

  font-size: 14px;

  font-weight: 700;

  letter-spacing: 0px;
}

/*
source
http://stackoverflow.com/a/23935891/3853728
*/

.img-gradient {
  position: relative;
}

/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
.img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 800px;
  display: inline-block;
  background: -moz-linear-gradient(
    top,
    var(--secondary-color-rgb) 0%,
    var(--primary-color-rgb) 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, var(--primary-color-rgb)),
    color-stop(100%, var(--secondary-color-rgb))
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    var(--secondary-color-rgb) 0%,
    var(--primary-color-rgb) 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    var(--secondary-color-rgb) 0%,
    var(--primary-color-rgb) 100%
  );
  /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    var(--secondary-color-rgb) 0%,
    var(--primary-color-rgb) 100%
  );
  /* IE10+ */
  background: linear-gradient(
    to bottom,
    var(--secondary-color-rgb) 0%,
    var(--primary-color-rgb) 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002f4b', endColorstr='#00000000', GradientType=0);
  /* IE6-9 */
}

.img-gradient img {
  display: block;
}

@media (max-width: 767px) {
  .img-gradient:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 450px;
    display: inline-block;
    background: -moz-linear-gradient(
      top,
      rgba(0, 47, 75, 0.5) 0%,
      var(--primary-color-rgb) 100%
    );
    /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, var(--primary-color-rgb)),
      color-stop(100%, rgba(0, 47, 75, 0.5))
    );
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 47, 75, 0.5) 0%,
      var(--primary-color-rgb) 100%
    );
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      top,
      rgba(0, 47, 75, 0.5) 0%,
      var(--primary-color-rgb) 100%
    );
    /* Opera 11.10+ */
    background: -ms-linear-gradient(
      top,
      rgba(0, 47, 75, 0.5) 0%,
      var(--primary-color-rgb) 100%
    );
    /* IE10+ */
    background: linear-gradient(
      to bottom,
      rgba(0, 47, 75, 0.5) 0%,
      var(--primary-color-rgb) 100%
    );
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002f4b', endColorstr='#00000000', GradientType=0);
    /* IE6-9 */
  }
}

.theme-btn:before {
  position: absolute;

  content: "";

  width: 100%;

  height: 100%;

  border: 1px solid #ffffff;

  left: 10px;

  top: 10px;

  z-index: -1;
}

/* slider */

.single-slider {
  background-size: cover;

  background-position: center center;
}

.slider-height {
  min-height: 800px;
}

.slider-content h1 {
  font-size: 60px;
}

.slider-content h1 span {
  font-size: 60px;
}

.slider-content p {
  font-size: 16px;

  color: #fff;

  line-height: 28px;

  margin-top: 18px;

  margin-bottom: 38px;
}

.slider-content-2 h1 {
  font-size: 72px;

  line-height: 85px;
}

.slider-content h1 {
  color: #fff;
}

@media (max-width: 767px) {
  .slider-height {
    min-height: 450px;
  }

  .slider-content h1 span {
    font-size: 35px;
  }

  .slider-content h1 {
    font-size: 30px;
    line-height: 43px;
  }
}

/*------------------------------------------*/
/*  Gallery Img
/*------------------------------------------*/

.de-image-hover {
  position: relative;
  overflow: hidden;
}

.de-image-hover:hover .dih-overlay {
  opacity: 0.6;
  transition: 0.2s all ease-out;
}

.de-image-hover img {
  transform: scale(1);
  transition: 0.5s all ease-out;
}

.de-image-hover:hover img {
  transform: scale(1.1);
}

.de-image-hover:hover {
  -webkit-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
}

.dih-overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  opacity: 0;
  transition: 0.2s all ease-out;
}

/* Gallery style end */

.inner-img:hover {
  transform: scale(1.1);
}

.hover-color {
  background-color: var(--primary-color);
}

/*------------------------------------------*/
/*  BACKGROUND SETTINGS
/*------------------------------------------*/

.bg--fixed,
.bg--scroll {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg--scroll {
  background-attachment: fixed !important;
}

/*------------------------------------------*/
/*  BACKGROUND SHAPE
/*------------------------------------------*/

.white--shape:after {
  background-color: #fff;
  -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
}

.theme--dark .white--shape:after {
  background-color: #1c1d1e;
  -webkit-box-shadow: 0 6px 12px 0 rgba(10, 10, 10, 0.06);
  -moz-box-shadow: 0 6px 12px 0 rgba(10, 10, 10, 0.06);
  box-shadow: 0 6px 12px 0 rgba(10, 10, 10, 0.06);
}

.theme--dark .blush--shape:after,
.theme--dark .ivory--shape:after,
.theme--dark .poudre--shape:after,
.theme--dark .smoke--shape:after,
.theme--dark .stone--shape:after {
  background-color: #1c1d1e;
}

/*------------------------------------------*/
/*  BACKGROUND IMAGE
/*------------------------------------------*/

.bg--01,
.bg--02,
.bg--03,
.bg--04,
.bg--05,
.bg--06 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #363636;
  /* font-family: "Vollkorn", serif; */
  letter-spacing: 0;
  letter-spacing: -0.75px;
  font-weight: 400;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  HEADER SIZE
/*------------------------------------------*/

h6 {
  font-size: 1rem;
}

/* 16px */
h6.h6-md {
  font-size: 1.125rem;
}

/* 18px */
h6.h6-lg {
  font-size: 1.25rem;
}

/* 20px */

h5 {
  font-size: 1.375rem;
}

/* 22px */
h5.h5-md {
  font-size: 1.5rem;
}

/* 24px */
h5.h5-lg {
  font-size: 1.625rem;
}

/* 26px */

h4 {
  font-size: 1.75rem;
}

/* 28px */
h4.h4-md {
  font-size: 1.875rem;
}

/* 30px */
h4.h4-lg {
  font-size: 2rem;
}

/* 32px */

h3 {
  font-size: 2.125rem;
}

/* 34px */
h3.h3-md {
  font-size: 2.25rem;
}

/* 36px */
h3.h3-lg {
  font-size: 2.375rem;
}

/* 38px */

h2 {
  font-size: 2.75rem;
}

/* 44px */
h2.h2-md {
  font-size: 2.875rem;
}

/* 46px */
h2.h2-lg {
  font-size: 3rem;
}

/* 48px */
h2.h2-title {
  font-size: 3.25rem;
}

/* 52px */

/*------------------------------------------*/
/*  PARAGRAPHS
/*------------------------------------------*/

p {
  font-size: 1.0625rem;
}

/* -------------------------------------------

preloader

------------------------------------------- */

.preloader {
  background-color: black;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  margin: 0 auto;
}

.preloader .preloader-circle {
  width: 100px;
  height: 100px;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-top-color: var(--primary-color);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 10;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 5px 0 var(--secondary-color-rgb);
  box-shadow: 0 1px 5px 0 var(--secondary-color-rgb);
  background-color: var(--tertiary-color);
  -webkit-animation: zoom 2000ms infinite ease;
  animation: zoom 2000ms infinite ease;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.preloader .preloader-circle2 {
  border-top-color: #0078ff;
}

.preloader .preloader-img {
  position: absolute;
  top: 50%;
  z-index: 200;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 6px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.preloader .preloader-img img {
  max-width: 55px;
}

.preloader .pere-text strong {
  font-weight: 800;
  color: var(--primary-color);
  text-transform: uppercase;
}

/*------------------------------------------*/
/*  LINK SETTINGS
/*------------------------------------------*/

/*------------------------------------------*/
/*  CONTENT TABLES
/*------------------------------------------*/

.content-section .table > :not(caption) > * > * {
  background-color: transparent;
}

.txt-table h4,
.txt-table h3 {
  margin-bottom: 25px;
}

.txt-table table {
  margin-bottom: 0;
}

.txt-table .table td,
.txt-table .table th {
  padding: 14px 0;
  border-top: none;
  border-bottom: 1px dashed #aaa;
}

.theme--dark .txt-table .table td,
.theme--dark .txt-table .table th {
  border-bottom: 1px dashed #666;
}

.ct-table .txt-table .table td {
  font-size: 1.125rem !important;
}

.txt-table .table .last-tr td,
.txt-table .table .last-tr th {
  border-bottom: none;
}

td.text-end {
  color: #363636;
  font-weight: 600;
}

.ct-table .txt-table .table td.text-end {
  font-size: 1.0625rem !important;
}

.theme--dark tr td,
.theme--dark td.text-end,
.content-section .color--white .table td {
  color: #fff;
}

a {
  color: #6f6f6f;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

a:hover {
  color: #363636 !important;
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

/*------------------------------------------*/
/*  LISTS
/*------------------------------------------*/

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*------------------------------------------*/
/*  TEXT LIST
/*------------------------------------------*/

ul.simple-list {
  list-style: disc;
  margin-left: 15px;
}

ul.simple-list.long-list p {
  margin-bottom: 8px;
}

/*------------------------------------------*/
/*  BUTTON SETTINGS
/*------------------------------------------*/

.btn {
  background-color: transparent;
  font-size: 0.725rem;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1rem 1.8rem;
  border: 1.5px solid transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  BLACK BUTTON
/*------------------------------------------*/

.btn--black,
.hover--black:hover,
.scroll .hover--black:hover {
  color: #fff !important;
  background-color: #262b2f !important;
  border-color: #262b2f !important;
}

.btn--tra-black,
.hover--tra-black:hover,
.scroll .hover--tra-black:hover,
.white-scroll .scroll .hover--tra-black:hover,
.black-scroll .scroll .hover--tra-black:hover {
  color: black !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  box-shadow: inset 0 0 0 0.15rem var(--primary-color) !important;
}

.btn--tra-secondary,
.hover--tra-secondary:hover,
.scroll .hover--tra-secondary:hover,
.white-scroll .scroll .hover--tra-secondary:hover,
.secondary-scroll .scroll .hover--tra-secondary:hover {
  color: var(--secondary-color) !important;
  background-color: transparent !important;
  border-color: var(--secondary-color) !important;
}

.theme--dark .btn--black,
.theme--dark .hover--black:hover {
  color: #363636 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.theme--dark .btn--tra-black,
.theme--dark .hover--tra-black:hover,
.theme--dark .scroll .hover--tra-black:hover,
.theme--dark .white-scroll .scroll .hover--tra-black:hover,
.theme--dark .black-scroll .scroll .hover--tra-black:hover {
  color: #e5e5e5 !important;
  background-color: transparent !important;
  border-color: #e5e5e5 !important;
}

/*------------------------------------------*/
/*  WHITE BUTTON
/*------------------------------------------*/

.btn--white,
.hover--white:hover {
  color: #363636 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn--tra-white,
.hover--tra-white:hover {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #fff !important;
}

.white-scroll .scroll .hover--tra-white:hover {
  color: #363636 !important;
  background-color: transparent !important;
  border-color: #363636 !important;
}

.dark-menu.white-scroll .scroll .hover--tra-white:hover {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #fff !important;
}

.white-scroll .scroll .hover--white:hover {
  color: #fff !important;
  background-color: #262b2f !important;
  border-color: #262b2f !important;
}

/*------------------------------------------*/
/*  BUTTON FOCUS
/*------------------------------------------*/

.btn:focus,
.btn.btn--black:focus {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn--tra-black:focus {
  color: #363636;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  VECTOR ICONS
/*------------------------------------------*/

.ico-65 [class*="flaticon-"]:before,
.ico-65 [class*="flaticon-"]:after {
  font-size: 4.0625rem;
}

/* 65px */

/*------------------------------------------*/
/*  MORE BUTTON
/*------------------------------------------*/

.more-btn {
  text-align: center;
}

/*------------------------------------------*/
/*  IMAGE HOVER OVERLAY
/*------------------------------------------*/

.hover-overlay {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
}

.hover-overlay img {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  overflow: hidden;
  -webkit-transition: transform 400ms;
  -moz-transition: transform 400ms;
  -o-transition: transform 400ms;
  transition: transform 400ms;
}

/*------------------------------------------*/
/*  Overlay Background 
/*------------------------------------------*/

.item-overlay {
  opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 20, 20, 0.5);
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  SECTION ID
/*------------------------------------------*/

.section-id {
  display: block;
  font-size: 0.85rem;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 30px;
}

/* ==========================================================================
  04. HEADER & NAVIGATION
  =========================================================================== */

#header {
  width: 100%;
  display: block;
  padding-top: 0px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.headerwp {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
}

.posrlt {
  position: relative;
}

/*------------------------------------------*/
/*  Header Img
/*------------------------------------------*/
.header-img-custom {
  height: 85vh;
  width: 100%;
}

@media (min-width: 678px) {
  .header-img-custom {
    width: 100%;
    height: 100%;
  }
}

/*------------------------------------------*/
/*  HEADER LOGO
/*------------------------------------------*/

.desktoplogo {
  line-height: 66px;
}

.desktoplogo img {
  width: auto;
  max-width: inherit;
  max-height: 70px;
}

.desktoplogo.logo-square img {
  max-height: 54px;
}

/*------------------------------------------*/
/*  NAVIGATION MENU
/*------------------------------------------*/

.wsmainfull {
  width: 100%;
  height: auto;
  z-index: 1031;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.tra-menu .wsmainfull {
  padding: 20px 0;
  background-color: transparent !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  HEADER LINK
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > a {
  display: block;
  font-size: 0.8rem;
  line-height: 50px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
  margin: 0 7px;
  padding: 10px 15px;
}

.navbar-dark .wsmenu > .wsmenu-list > li > a.h-link {
  color: #363636;
}

.navbar-light .wsmenu > .wsmenu-list > li > a.h-link,
.theme--dark .navbar-dark .wsmenu > .wsmenu-list > li > a.h-link {
  color: #fff;
}

.wsmenu > .wsmenu-list > li > a.h-link.last-link {
  padding: 10px 0px;
  margin: 0;
}

.wsmenu > .wsmenu-list > li > a .wsarrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid;
  content: "";
  float: right;
  right: 0;
  height: 0;
  margin: 0 0 0 14px;
  position: absolute;
  text-align: right;
  top: 33px;
  width: 0;
}

/*------------------------------------------*/
/*  HEADER BUTTONS
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li a.btn {
  font-size: 0.75rem;
  line-height: 30px;
  padding: 4px 28px;
  margin: 14px 0 0 8px;
}

/*------------------------------------------*/
/*  HEADER SUBMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > ul.sub-menu {
  width: auto;
  min-width: 220px;
  top: 62px;
  padding: 16px 25px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu,
.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu {
  background-color: #151515;
  border-color: #151515;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
  background-color: rgba(244, 244, 249, 0);
  color: #363636;
  font-size: 0.775rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 5px 0;
  transition: all 0.3s ease-in-out;
}

.dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a,
.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
  color: #ccc;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
  background-color: transparent !important;
  padding: 5px 0 5px 5px;
}

/*------------------------------------------*/
/*  HALFMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > .wsmegamenu {
  top: 62px;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  width: 36%;
  padding: 18px 14px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.dark-menu .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu,
.theme--dark .wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu {
  background-color: #151515;
  border-color: #151515;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu.icons-menu {
  right: 2%;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu .link-list li {
  border-bottom: none;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a {
  background-color: rgba(244, 244, 249, 0);
  color: #363636;
  font-size: 0.775rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 5px 0;
  transition: all 0.3s ease-in-out;
}

.dark-menu
  .wsmenu
  > .wsmenu-list
  > li
  > .wsmegamenu.halfmenu
  ul.link-list
  > li
  > a,
.theme--dark
  .wsmenu
  > .wsmenu-list
  > li
  > .wsmegamenu.halfmenu
  ul.link-list
  > li
  > a {
  color: #ccc;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  background-color: transparent !important;
  padding: 5px 0 5px 5px;
}

/*------------------------------------------*/
/*  NAVBAR HOVER
/*------------------------------------------*/

.navbar-light .wsmenu-list > li > a.h-link:hover {
  color: #eee !important;
}

.navbar-dark .wsmenu-list > li > a.h-link:hover {
  color: #1d222f !important;
}

.dark-menu .wsmenu-list > li > a.h-link:hover {
  color: #ccc !important;
}

.theme--dark .navbar-light .wsmenu-list > li > a.h-link:hover,
.theme--dark .navbar-dark .wsmenu-list > li > a.h-link:hover {
  color: #ccc !important;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
.wsmenu > .wsmenu-list > li > .wsmegamenu.w-75 ul.link-list > li > a:hover,
.wsmenu > .wsmenu-list > li > .wsmegamenu.halfmenu ul.link-list > li > a:hover {
  color: #363636 !important;
}

.dark-menu .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
.dark-menu
  .wsmenu
  > .wsmenu-list
  > li
  > .wsmegamenu.w-75
  ul.link-list
  > li
  > a:hover,
.dark-menu
  .wsmenu
  > .wsmenu-list
  > li
  > .wsmegamenu.halfmenu
  ul.link-list
  > li
  > a:hover {
  color: #ccc !important;
}

.theme--dark .wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover,
.theme--dark
  .wsmenu
  > .wsmenu-list
  > li
  > .wsmegamenu.w-75
  ul.link-list
  > li
  > a:hover,
.theme--dark
  .wsmenu
  > .wsmenu-list
  > li
  > .wsmegamenu.halfmenu
  ul.link-list
  > li
  > a:hover {
  color: #aaa !important;
}

.wsmenu > .wsmenu-list.nav-gold > li > ul.sub-menu > li > a:hover,
.wsmenu
  > .wsmenu-list.nav-gold
  > li
  > .wsmegamenu.w-75
  ul.link-list
  > li
  > a:hover,
.wsmenu
  > .wsmenu-list.nav-gold
  > li
  > .wsmegamenu.halfmenu
  ul.link-list
  > li
  > a:hover {
  color: #af8855 !important;
}

.wsmenu > .wsmenu-list.nav-magenta > li > ul.sub-menu > li > a:hover,
.wsmenu
  > .wsmenu-list.nav-magenta
  > li
  > .wsmegamenu.w-75
  ul.link-list
  > li
  > a:hover,
.wsmenu
  > .wsmenu-list.nav-magenta
  > li
  > .wsmegamenu.halfmenu
  ul.link-list
  > li
  > a:hover {
  color: #b25996 !important;
}

.wsmenu > .wsmenu-list.nav-red > li > ul.sub-menu > li > a:hover,
.wsmenu
  > .wsmenu-list.nav-red
  > li
  > .wsmegamenu.w-75
  ul.link-list
  > li
  > a:hover,
.wsmenu
  > .wsmenu-list.nav-red
  > li
  > .wsmegamenu.halfmenu
  ul.link-list
  > li
  > a:hover {
  color: #e55d42 !important;
}

/*------------------------------------------*/
/*  HIDDEN NAVBAR SCROLL
/*------------------------------------------*/

.hidden-nav .wsmainfull.scroll {
  margin-top: 0;
}

/*------------------------------------------*/
/*  NAVBAR WHITE SCROLL
/*------------------------------------------*/

.tra-menu.white-scroll .wsmainfull.scroll,
.white-menu.white-scroll .wsmainfull.scroll {
  padding: 0;
  background-color: #fff !important;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.theme--dark .tra-menu.white-scroll .wsmainfull.scroll,
.theme--dark .white-menu.white-scroll .wsmainfull.scroll {
  background-color: #151515 !important;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

.tra-menu.navbar-dark.white-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  > li
  > a.h-link,
.tra-menu.navbar-light.white-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  > li
  > a.h-link {
  color: #353f4f;
}

.theme--dark
  .tra-menu.navbar-dark.white-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  > li
  > a.h-link,
.theme--dark
  .tra-menu.navbar-light.white-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  > li
  > a.h-link {
  color: #fff;
}

.navbar-light.white-scroll .scroll .wsmenu-list > li > a.h-link:hover {
  color: #1c1c1c !important;
}

.theme--dark
  .navbar-light.white-scroll
  .scroll
  .wsmenu-list
  > li
  > a.h-link:hover {
  color: #ccc !important;
}

/*------------------------------------------*/
/*  NAVBAR BLACK SCROLL
/*------------------------------------------*/

.tra-menu.black-scroll .wsmainfull.scroll,
.dark-menu.black-scroll .wsmainfull.scroll {
  padding: 0;
  background-color: #151515 !important;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

.tra-menu.navbar-dark.black-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  > li
  > a.h-link {
  color: #fff;
}

.tra-menu.navbar-dark.black-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  > li
  > a.h-link:hover {
  color: #ccc !important;
}

/*------------------------------------------*/
/*  WHITE SCROLL BUTTONS
/*------------------------------------------*/

.white-scroll .scroll .btn--tra-white {
  color: #353f4f !important;
  border-color: #353f4f !important;
}

.theme--dark .white-scroll .scroll .btn--tra-white {
  color: #fff !important;
  border-color: #fff !important;
}

.theme--dark .white-scroll .scroll .hover--white:hover {
  color: #363636 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

/*------------------------------------------*/
/*  BLACK SCROLL BUTTONS
/*------------------------------------------*/

.black-scroll .scroll .btn--tra-black {
  color: #fff !important;
  border-color: #fff !important;
}

/*------------------------------------------*/
/*  LOGO IMAGE
/*------------------------------------------*/

.logo-white,
.logo-black {
  display: block;
}

.navbar-light .logo-black,
.navbar-dark .logo-white,
.theme--dark .navbar-dark .logo-black,
.tra-menu.navbar-light .scroll .logo-white,
.tra-menu.navbar-dark.black-scroll .scroll .logo-black,
.theme--dark .tra-menu.navbar-light .scroll .logo-black {
  display: none;
}

.theme--dark .navbar-dark .logo-white,
.tra-menu.navbar-light .scroll .logo-black,
.tra-menu.navbar-dark.black-scroll .scroll .logo-white,
.theme--dark .tra-menu.navbar-light .scroll .logo-white {
  display: block;
}

/*------------------------------------------*/
/*  HERO-1
/*------------------------------------------*/

#hero-1 .caption {
  margin-top: 60px;
}

#hero-1 .caption h2 {
  font-size: 5.75rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0;
}

#hero-1 .caption h5 {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 15px;
}

#hero-1 .caption .btn {
  margin-top: 40px;
}

/*------------------------------------------*/
/*  ABOUT TYPOGRAPHY
/*------------------------------------------*/

.about-1 .txt-block p,
.about-2 .txt-block p {
  padding: 0 3%;
}

/*------------------------------------------*/
/*  SERVICE BOX ICON
/*------------------------------------------*/

.sbox-ico {
  margin-bottom: 20px;
}

.sbox-4 h6,
.sbox-4 h5,
.sbox-5 h6,
.sbox-5 h5,
.sbox-txt h6,
.sbox-txt h5,
.sbox-txt h4 {
  margin-bottom: 10px;
}

.sbox-txt p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  TEXT BLOCK
/*------------------------------------------*/

.ct-01 .txt-block {
  padding-right: 60px;
}

.ct-02 .txt-block {
  padding-left: 60px;
}

.ct-03 .left-column {
  padding-right: 15px;
}

.ct-03 .left-column .ct-03-txt {
  padding-right: 45px;
}

.ct-03 .right-column {
  padding-left: 15px;
}

.ct-05 .txt-block {
  padding: 130px 25px 130px 60px;
}

.ct-06 .txt-block {
  padding: 130px 45px 130px 40px;
}

.ct-07 .txt-block {
  padding: 0 40px 0 80px;
}

.ct-08 .txt-block {
  padding: 0 70px 0 50px;
}

.ct-table .left-column {
  padding-right: 25px;
}

.ct-13 .txt-block {
  padding: 30px 40px;
  margin-left: 20px;
  margin-right: 30px;
}

/*------------------------------------------*/
/*  TEXT BLOCK TYPOGRAPHY
/*------------------------------------------*/

.ct-table h3,
.ct-table h2,
.txt-block h2 {
  margin-bottom: 20px;
}

.ct-09-txt h3,
.ct-09-txt h2 {
  margin-top: 50px;
  margin-bottom: 0;
}

.ct-13 .txt-block h4 {
  margin-bottom: 20px;
}

p.ct-09-address {
  color: #363636;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}

.theme--dark p.ct-09-address {
  color: #fff;
}

.txt-block .btn {
  margin-top: 30px;
}

/* ==========================================================================
  15. PRICING
  ========================================================================== */

/*------------------------------------------*/
/*  PRICING LIST
/*------------------------------------------*/

.pricing-list li {
  margin-bottom: 22px;
}

.pricing-5-table .pricing-list li {
  margin-bottom: 30px;
}

.pricing-list li:last-child,
.pricing-4 .pricing-list li:last-child {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  PRICING DETAILS
/*------------------------------------------*/

.detail-price {
  margin: 0;
  display: table;
}

.price-name {
  position: relative;
  display: table-cell;
  width: 1%;
  white-space: nowrap;
  padding: 0 10px 0 0;
}

.price-dots {
  position: relative;
  display: table-cell;
  height: 3px;
  width: 98%;
  z-index: 1;
  background-image: radial-gradient(
    circle closest-side,
    #878889 99%,
    transparent 100%
  );
  background-position: 50% 100%;
  background-size: 5px 2px;
  background-repeat: repeat-x;
}

.theme--dark .price-dots {
  background-image: radial-gradient(
    circle closest-side,
    #666 99%,
    transparent 100%
  );
}

.price-number {
  display: table-cell;
  width: 1%;
  padding: 0 0 0 10px;
  white-space: nowrap;
}

.price-name p,
.price-number p {
  color: #363636;
  position: relative;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 0;
}

.theme--dark .price-name p,
.theme--dark .price-number p {
  color: #fff;
}

.price-number p {
  font-weight: 400;
}

.pricing-5 .price-name p,
.pricing-5 .price-number p {
  font-size: 1.1875rem;
}

.pricing-7 .price-name p,
.pricing-7 .price-number p {
  font-size: 1.15rem;
}

.pricing-4 .color--white .price-name p,
.pricing-4 .color--white .price-number p {
  color: #fff;
}

.pricing-6-item p {
  font-size: 1rem;
  margin-bottom: 10px;
}

.pricing-6-item p.pricing-6-title {
  color: #363636;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0;
  margin-bottom: 2px;
}

.pricing-6-item p.pricing-6-number {
  color: #363636;
  font-size: 1.15rem;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Pricing Item Description
/*------------------------------------------*/

.price-txt {
  margin-top: 6px;
}

.price-txt p {
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0;
}

.pricing-4 .color--white .price-txt p {
  color: #e2e3e4;
}

.pricing-notice p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  PRICING IMAGE
/*------------------------------------------*/

.pricing-2-img {
  margin-right: -60px;
}

.pricing-3-img {
  margin-left: -60px;
}

.pricing-6-img {
  margin-bottom: 25px;
}

.pricing-6-img .item-overlay {
  background: rgba(10, 10, 10, 0.1);
}

.pricing-6-item:hover img {
  transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.pricing-6-item:hover .item-overlay {
  opacity: 1;
  -moz-opacity: 1;
  bottom: 0;
}

/* ==========================================================================
  24. FOOTER
  ========================================================================== */

.footer-area .footer-top {
  border-bottom: 1px solid #233148;
}

.footer-area .footer-top .single-footer-caption .footer-logo {
  margin-bottom: 30px;
}

.footer-area .footer-top .single-footer-caption .footer-pera .info1 {
  line-height: 1.8;
  margin-bottom: 35px;
  color: #7c7c7c;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-area .footer-top .single-footer-caption .footer-pera .info1 {
    padding-right: 0px;
  }
}

.footer-area .footer-top .single-footer-caption .footer-number h4 {
  /* color: var(--primary-color); */
  color: var(--secondary-color);
  font-size: 30px;
  font-weight: 500;
  text-transform: capitalize;
}

.footer-area .footer-top .single-footer-caption .footer-number h4 span {
  color: #fff;
}

.footer-area .footer-top .single-footer-caption .footer-number p {
  color: #bdbdbd;
}

.footer-area .footer-top .single-footer-caption .footer-tittle h4 {
  color: #fff;
  /* font-family: "Poppins", sans-serif; */
  font-size: 18px;
  margin-bottom: 38px;
  font-weight: 500;
  text-transform: uppercase;
}

.footer-area .footer-top .single-footer-caption .footer-tittle ul li {
  color: #7c7c7c;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.footer-area .footer-top .single-footer-caption .footer-tittle ul li a {
  color: #7c7c7c;
  font-weight: 300;
  font-size: 15px;
}

.footer-area .footer-top .single-footer-caption .footer-tittle ul li a:hover {
  color: #fff;
  padding-left: 5px;
}

.footer-area .footer-top .single-footer-caption .footer-form {
  margin-bottom: 30px;
}

.footer-area .footer-top .single-footer-caption .footer-form form {
  position: relative;
}

.footer-area .footer-top .single-footer-caption .footer-form form input {
  width: 100%;
  height: 43px;
  padding: 10px 20px;
  border: 0;
  background: #101720;
  color: #fff;
}

.footer-area
  .footer-top
  .single-footer-caption
  .footer-form
  form
  input::placeholder {
  color: #9fa6bd;
  font-weight: 300;
  font-size: 14px;
}

.footer-area
  .footer-top
  .single-footer-caption
  .footer-form
  form
  .form-icon
  button {
  position: absolute;
  top: 0px;
  right: 0;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 14px 22px;
  background: #0a0f17;
  line-height: 1;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 300;
}

.footer-area .footer-top .single-footer-caption .info.error {
  color: #ff0000;
}

.footer-area .footer-top .single-footer-caption .footer-cap {
  margin-bottom: 30px;
}

.footer-area .footer-top .single-footer-caption .footer-cap span {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
  display: block;
}

.footer-area .footer-top .single-footer-caption .footer-cap p {
  color: #7c7c7c;
  font-size: 16px;
}

.footer-area .footer-bottom {
  padding-bottom: 50px;
  padding-top: 30px;
}

.footer-area .footer-bottom .footer-copy-right p {
  color: #7c7c7c;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  margin-bottom: 12px;
}

.footer-area .footer-bottom .footer-copy-right p i {
  color: var(--primary-color);
}

.footer-area .footer-bottom .footer-copy-right p a {
  color: var(--primary-color);
}

.footer-area .footer-bottom .footer-copy-right p a:hover {
  color: #fff;
}

.footer-area .footer-social span {
  color: #fff;
  position: relative;
  margin-right: 57px;
}

.footer-area .footer-social span::before {
  position: absolute;
  content: "";
  width: 43px;
  height: 2px;
  background: #3b3647;
  top: 50%;
  transform: translateY(-50%);
  right: -56px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-area .footer-social {
    float: left;
    padding-top: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-area .footer-social {
    float: left;
    padding-top: 10px;
  }
}

@media (max-width: 575px) {
  .footer-area .footer-social {
    float: left;
    padding-top: 10px;
  }
}

.footer-area .footer-social a {
  color: #7c7c7c;
  font-size: 14px;
  padding-left: 25px;
}

.footer-area .footer-social a i {
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  transform: rotateY(0deg);
}

.footer-area .footer-social a:hover {
  color: #fff;
}

.footer-area .footer-social a:hover i {
  transform: rotateY(180deg);
}

/*------------------------------------------------------------------------ */

.footer {
  padding-bottom: 50px;
}

.footer-info,
.footer-form,
.footer-links,
.footer-contacts,
.footer .google-map {
  margin-bottom: 40px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER
/*------------------------------------------*/

.footer hr {
  margin-top: 30px;
  margin-bottom: 50px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER COPYRIGHT
/*------------------------------------------*/

.footer-copyright p {
  margin-bottom: 0;
}

.section-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

/*------------------------------------------*/
/*  back to top
/*------------------------------------------*/
#scrollUp,
#back-top {
  background: var(--primary-color);
  height: 50px;
  width: 50px;
  right: 31px;
  z-index: 9999;
  bottom: 18px;
  position: fixed;
  color: #fff;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  line-height: 48px;
  border: 2px solid transparent;
  box-shadow: 0 0 10px 3px rgba(108, 98, 98, 0.2);
}
